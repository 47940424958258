const lng = localStorage.getItem("default-lg") || "ar";

export const getSearchCustomFields = (data) => {
  const customFields = [];
  let updatedSearchParams = {};
  console.log(data, "DATA");

  for (const key in data) {
    if (key.startsWith("custom_fields[")) {
      const id = key.match(/\[([0-9]+)\]/)[1];
      const value = data[key];

      if (data[key] != "0") {
        customFields.push({
          id: parseInt(id, 10),
          value: value,
        });
      }
    } else {
      updatedSearchParams[key] = data[key];
    }
  }

  return [customFields, updatedSearchParams];
};
export const normalizedDataToFormData = (formData, customFieldsData) => {
  const result = [];
  const allFields = Object.values(customFieldsData).flatMap(
    (category) => category.fields
  );
  Object.entries(formData).forEach(([key, value]) => {
    const fieldData = allFields.find(({ id }) => id == key);
    if (fieldData) {
      if (fieldData.type === "select") {
        result.push({
          content: "",
          custom_field_id: key,
          option_id: value,
        });
      } else {
        result.push({
          content: value,
          custom_field_id: key,
          option_id: null,
        });
      }
    }
  });
  return result;
};
export const normalizedDataFromCustomFields = (data) => {
  return data.map((d) => ({
    icon: d.custom_field.icon,
    title: d.custom_field.title,
    value:
      d.custom_field.type === "select"
        ? d.custom_field_option.value
        : d.content,
    type: d.custom_field.type,
  }));
};

export const hasUnits = (data) => {
  return data?.has_units === 1;
};

export const toQueryString = (params) => {
  return Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
};

export const getDateOnly = (DATE, onlyYear = false) => {
  const date = new Date(DATE);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = onlyYear ? year : `${year}-${month}-${day}`;
  return formattedDate;
};

export function translate(title, en_title) {
  return LANGUAGE === "ar" ? title : en_title;
}
export const getFileIconSrc = (file) => {
  const extension = file?.path.split(".").pop().toLowerCase();
  switch (extension) {
    case "pdf":
      return "/pdf-file.svg";
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return file?.url;
    case "doc":
    case "docx":
      return "/pdf-file.svg";
    default:
      return "/pdf-file.svg"; // Default to PDF if the file type is unknown
  }
};

export const transDataToTree = (data, codes) => {
  const idMap = new Map();

  // First pass: create a map of all nodes

  data?.forEach((node) => {
    idMap.set(node.id, {
      value: node.id,
      label: node.name,
      code: node.code,
      parent_id: node.parent_id,
      children: [],
      en_label: node.en_name,
      agent_id: node.agent_id,
      cash_flow_type: node.cash_flow_type,
    });
  });

  const treeData = [];

  // Second pass: build the tree
  data?.forEach((node) => {
    const currentNode = idMap.get(node.id);

    const condition = codes
      ? codes.includes(node.code)
      : node.parent_id === null;

    if (condition) {
      treeData.push(currentNode);
    } else {
      const parent = idMap.get(node?.parent_id);

      if (parent) {
        parent.children.push(currentNode);
      } else {
        console.warn(`Parent not found for node: ${node.id}`);
      }
    }
  });

  return treeData;
};

export const direction = () => {
  return lng === "ar" ? "rtl" : "ltr";
};

const currency = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return lng === "ar"
    ? user?.agent?.currency?.name
    : user?.agent?.currency?.en_name;
};
export const CURRENCY = currency();
export const LANGUAGE = lng;
export const DIRECTION = direction();

export const findPathById = (treeData, id) => {
  const parentIds = [];
  function searchAndAddParentIds(nodes, targetId) {
    for (let node of nodes) {
      if (node.id === targetId) {
        parentIds.push(node.id);
        if (node.parent_id !== null) {
          searchAndAddParentIds(treeData, node.parent_id);
        }
        return true;
      }
    }
    return false;
  }
  searchAndAddParentIds(treeData, id);

  return parentIds.reverse();
};

export const findPathByIdWithCode = (treeData, id, code) => {
  const parentIds = [];
  function searchAndAddParentIds(nodes, targetId) {
    for (let node of nodes) {
      if (node.id === targetId) {
        parentIds.push(node.id);
        if (node.parent_id !== null && code !== node.code) {
          searchAndAddParentIds(treeData, node.parent_id);
        }
        return true;
      }
    }
    return false;
  }
  searchAndAddParentIds(treeData, id);

  return parentIds.reverse();
};

export const getFormattedDateTime = (date) => {
  const now = new Date(date);

  let formattedDate;
  let formattedTime;

  if (LANGUAGE === "ar") {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    formattedDate = now.toLocaleDateString("ar-EG-u-nu-arab", options);
    formattedTime = now.toLocaleTimeString("ar-EG-u-nu-arab", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    return `${formattedDate} ${formattedTime}`;
  } else {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    formattedDate = now.toLocaleDateString(LANGUAGE, options);
    formattedTime = now.toLocaleTimeString(LANGUAGE, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    return `${formattedDate} ${formattedTime}`;
  }
};

export const convertToArabicNumerals = (number) => {
  const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  const num =
    LANGUAGE === "en"
      ? number
      : number
          .toString()
          .split("")
          .map((digit) => (/\d/.test(digit) ? arabicNumerals[digit] : digit))
          .join("");
  return num + " ";
};

export const dueStats = (collectedAt, dueDate) => {
  let value = {};
  const now = new Date();

  if (collectedAt !== null) {
    value = { title: "محصله", en_title: "collected" };
  } else {
    const DUE_DATE = new Date(dueDate);

    if (DUE_DATE.getTime() < now.getTime()) {
      value = { title: "متأخرة", en_title: "delayed" };
    } else {
      value = { title: "مجدوله", en_title: "scheduled" };
    }
  }
  return translate(value.title, value.en_title);
};
